.social-links {
  display: flex;
  justify-content: space-around;
  padding: 0 8px;

  a {
    display: inline-block;
    padding: 0 8px;
  }

  a[aria-disabled="true"] {
    opacity: 0.2;
  }


  img {
    width: 24px;
    pointer-events: none;
  }
}
