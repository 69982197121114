@import "variables";

html {
  font-size: 1px;
  height: 100%;
}

body {
  font-family: $system-font;
  font-size: 13rem;
  text-align: center;
  height: 100%;
  background-color: $gray-600;
}

h1 {
  margin: 0;
  font-size: 1.5em;
}

h2 {
  margin: 0;
  font-size: 1.35em;
}

h3 {
  margin: 0;
  font-size: 1.2em;
}

p {
  margin: 16rem 0;
}

a {
  color: $primary-color;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

a.button {
  background-color: $gray-100;
  padding: 0.8em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-md;
  flex: 1;
  font-size: 1.1em;

  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}

a.button.primary {
  color: white;
  background-color: $primary-color;
  font-weight: 500;
}

#main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  max-width: 425px;
  margin: 0 auto;

  .container {
    padding: 8rem;
    margin: 16rem;
    margin-top: 0;
    background-color: white;
    border-radius: $border-radius-lg;
    color: $gray-500;
  }
}

.flex-spacer {
  flex: 1;
}

.buttons {
  display: flex;
  margin-top: 8rem;

  .button + .button {
    margin-left: 8rem;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 2px solid $primary-color;
  border-right-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  animation: spin 1s infinite linear;
}

[aria-disabled="true"] {
  pointer-events: none;
  color: #888888;
  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
