@import "../css/variables";

#logo-header {
  margin: 16rem;

  img {
    height: 36rem;
  }
}

header {
  display: flex;
  flex-direction: row;
  margin: 8rem 0;

  #qr-code {
    width: 25%;
    font-size: 0;

    > canvas {
      max-width: 100%;
      width: 100% !important;
      height: auto !important;
      image-rendering: pixelated;
    }
  }

  #style-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex: 1;
  }

  #style-author {
    margin: 4rem 0;
    font-size: 1.1em;
    font-weight: 500;
  }

  #style-name {
    margin: 4rem 0;
  }

  #style-count {
  }
}

section {

  #preview {
    padding-top: 100%;
    background-color: $gray-600;
    color: $gray-100;
    position: relative;
    overflow: hidden;
    border-radius: 8rem;
  }
}

footer {
}
