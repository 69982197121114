#split-preview {
  touch-action: none;

  .split {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    user-select: none;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
