// Colors
$primary-color: rgb(0, 111, 191);
$gray-100: rgb(238, 238, 247);
$gray-500: rgb(43, 43, 45);
$gray-600: rgb(11, 11, 13);

// Vars
$border-radius-sm: 4rem;
$border-radius-md: 8rem;
$border-radius-lg: 16rem;

// Fonts
$system-font: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
"Helvetica Neue", Arial, sans-serif;
