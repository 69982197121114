@import "../css/variables";

.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.5);
  transition: opacity 0.2s ease-out;
  opacity: 0;

  .dialog-content {
    background-color: white;
    border-radius: $border-radius-lg;
    min-width: 66%;
    max-width: 320px;
    box-shadow: 0 8px 32px rgba(black, 0.3);
    transition: all 0.2s ease-out;
    transform: scale(0.7);
  }

  h2 {
    margin: 16rem;
  }

  .buttons {
    margin: 16rem;
  }

  &.active {
    opacity: 1;

    .dialog-content {
      transform: none;
    }
  }
}
